@tailwind base;
@tailwind components;
label{
    @apply text-[#64709B] text-xs mb-1 
  }
  label span{
    @apply text-[#E61E50]
  }
  input{
    @apply py-1 px-2.5 min-h-10 w-full rounded-md focus:outline-none text-[#64709B]  disabled:opacity-60  text-sm border border-[#f3f4f6]
  }
  .error{
    @apply text-red-600 text-xs
   }
   .primaryButton{
    @apply w-full uppercase md:text-base text-sm bg-[#1890FF] border-[#1890FF] text-white py-2 px-3 rounded-sm hover:bg-blue-600 border hover:border-blue-600 transition duration-300  disabled:bg-[#838F9C]
  }
  textarea{
    @apply py-1 px-2.5 min-h-10 w-full rounded-md focus:outline-none text-[#64709B] disabled:opacity-70 text-sm
  }
  select{
    @apply py-1 px-2.5 min-h-10 w-full rounded-md focus:outline-none text-[#64709B]  text-sm border border-[#f3f4f6]
  }
  .secondaryButton{
    @apply w-full md:text-base text-sm bg-transparent border border-[#1890FF] text-[#1890FF] py-2 px-3 h-fit rounded-sm hover:border-blue-600 disabled:border-[#838F9C] disabled:text-[#838F9C] transition duration-300  disabled:bg-gray-100
  }
  .ghostButton{
    @apply uppercase text-[#1890FF] hover:underline rounded-lg hover:text-blue-700 transition duration-300  disabled:text-[#838F9C]
  }

  .textPrimary {
    @apply text-[#1890FF] font-semibold;
  }
  .textSecondary {
    @apply text-[#64709B] font-semibold mt-2 text-xs;
  }
  .textGreen{
    @apply text-[#15803D] font-semibold mt-2 text-xs
  }
  .textgrey {
    @apply text-[#7D7D89];
  }
@tailwind utilities;